import React from "react"
import styled from "styled-components"

const QuestionMarkIcon = ({ title, text }) => {
  return <QuestionIcon>
    <span>?</span>
    {
      title || text ?
      <Tooltip>
        <TooltipTitle>{title}</TooltipTitle>
        <div>{text}</div>
      </Tooltip> :
      null
    }
  </QuestionIcon>;
}

export default QuestionMarkIcon;

const QuestionIcon = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  margin-left: 10px;
  font-family: HelveticaNeueLTStd, sans-serif;
  font-size: 10px;
  font-weight: normal;
  color: #000;
  border: 1px solid #000;
  border-radius: 50%;
  cursor: pointer;
  
  :hover > span {
    visibility: visible;
    opacity: 1;
  }
`

const Tooltip = styled.span`
  position: absolute;
  top: 125%;
  left: 50%;
  visibility: hidden;
  width: 320px;
  margin-left: -160px;
  padding: 10px;
  font-size: 18px;
  color: #3C3B3B;
  background-color: #fff;
  border: solid 1px #3C3B3B;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s;
`

const TooltipTitle = styled.div`
  font-weight: bold;
  color: #8EC641;
`
